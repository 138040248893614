<template>
  <v-container v-if="!loading" id="briefing" fluid tag="section">
    <div
      v-if="showBriefingSecurityAlert"
      class="pa-6 ma-3"
      justify="center"
      style="
        display: flex;
        gap: 10px;
        color: #1a7dff;
        border-radius: 10px;
        border: 1px solid #1a7dff;
      "
    >
      <span style="display: block; font-size: 1.2rem">{{
        $t("banker_briefing_alert")
      }}</span>
      <div>
        <v-icon color="#1A7DFF">mdi-alert-circle</v-icon>
      </div>
    </div>
    <!-- Overall -->
    <BriefingHeader
      v-if="company"
      :Item="{
        Name: company.name,
        IntroVideo: company.briefing.introVideo,
        Hero: company.briefing.hero,
      }"
      :SignedNDA="signed_nda"
      :GoToOptions="go_to_options()"
      @go_to="scroll"
    />
    <AllocateBanner
      :Company="company"
      :Value="value"
      :Modal="openModal"
    ></AllocateBanner>
    <!-- Financial -->
    <BriefingFinancial
      id="financial"
      v-if="company != null"
      :company="company"
      :signed_nda="signed_nda"
    ></BriefingFinancial>
    <!-- Highlights -->
    <BriefingHighlights
      id="highlights"
      v-if="highlights.length > 0"
      :Highlights="highlights"
    />
    <!-- Company presentation -->
    <BriefingCompanyPresentation
      v-if="!slidesLoading && slides.length > 0"
      id="presentation"
      :Slides="slides"
      :IsVerticalPresentation="company.briefing.isVerticalPresentation"
      @fullscreen="presentation_fullscreen"
    ></BriefingCompanyPresentation>
    <v-row v-else-if="slidesLoading" justify="center" no-gutters>
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </v-row>
    <!-- Investment conditions -->
    <BriefingInvestmentConditions
      id="investment-conditions"
      :BriefingId="company.briefing.id"
      :PartnerB2bId="user.partnerB2bId"
    />
    <!-- Files -->
    <BriefingFiles
      downloadUrl="companyfile/download"
      id="files"
      :Files="files_filtered"
      :SignedNDA="signed_nda"
    />
    <!-- Team -->
    <BriefingTeam :BriefingId="company.briefing.id" id="team"></BriefingTeam>
    <!-- Leadership Chat -->
    <BriefingLeadershipChat
      :BriefingId="company.briefing.id"
      :MediaType="2"
      :Header="$t('leadership_chat')"
      id="leadership-chat"
    ></BriefingLeadershipChat>
    <!-- Analysis -->
    <BriefingAnalysis
      id="analysis"
      :analysis="company.briefing.companyAnalysis"
    ></BriefingAnalysis>
    <!-- Market news -->
    <BriefingMarketNews
      :BriefingId="company.briefing.id"
      id="market-news"
    ></BriefingMarketNews>
    <!-- Media -->
    <BriefingMedia
      :BriefingId="company.briefing.id"
      :MediaType="0"
      :Header="$t('media')"
      id="media"
    ></BriefingMedia>

    <v-row class="bottom-space">
      <div class="mx-auto mt-4">
        <a
          class="mr-4"
          style="text-decoration: underline"
          @click="openTermsOfUseOrPrivacyPolicy('termsOfUse')"
        >
          {{ $t("terms_conditions") }}
        </a>
        <a
          class="ml-4"
          style="text-decoration: underline"
          @click="openTermsOfUseOrPrivacyPolicy('privacyPolicy')"
        >
          {{ $t("privacy_policy") }}
        </a>
      </div>
    </v-row>
    <v-dialog v-model="dialog" v-if="dialog">
      <FullscreenPresentationDialog
        id="presentation_dialog"
        :Slides="slides"
      ></FullscreenPresentationDialog>
    </v-dialog>
  </v-container>

  <v-container v-else>
    <v-progress-circular
      style="margin: 0 auto; display: block"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<style lang="scss" scoped>
.bottom-space {
  padding-bottom: 5rem;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import BriefingCompanyPresentation from "./children/CompanyPresentation";
import FullscreenPresentationDialog from "./children/FullscreenPresentation";
import BriefingTeam from "./children/Team/Team";
import BriefingInvestmentConditions from "@/components/dxa/Briefing/InvestmentConditions";
import BriefingMarketNews from "./children/MarketNews";
import BriefingMedia from "./children/Media";
import BriefingHeader from "@/components/dxa/Briefing/Header";
import BriefingFinancial from "./children/Financial";
import BriefingHighlights from "@/components/dxa/Briefing/Highlights";
import BriefingLeadershipChat from "./children/Media";
import BriefingAnalysis from "./children/Analysis";
import BriefingFiles from "@/components/dxa/Briefing/Files";
import AllocateBanner from "./children/AllocateBanner";
import { mapState } from "vuex";
import { UserTypeEnum } from "@/shared/enums/UserType";
import mixpanel from "mixpanel-browser";

export default {
  name: "CompanyBriefing",

  components: {
    BriefingCompanyPresentation,
    BriefingTeam,
    BriefingInvestmentConditions,
    BriefingMarketNews,
    BriefingMedia,
    BriefingHeader,
    BriefingFinancial,
    BriefingHighlights,
    BriefingLeadershipChat,
    BriefingAnalysis,
    BriefingFiles,
    AllocateBanner,
    FullscreenPresentationDialog,
  },

  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    loading: false,
    company_id: null,
    signed_nda: false,
    company: null,
    value: null,
    dialog: false,
    openModal: false,
    slidesLoading: false,
    user: null,
    slides: [],
    files: [],
    files_filtered: [],
    highlights: [],
    highlights_filtered: [],
    privacyPolicyUrlPt:
      "https://www.dxainvest.com/legal/politica-de-privacidade",
    privacyPolicyUrlEn:
      "https://en.dxainvest.com/legal/politica-de-privacidade",
    termsOfUseUrlPt: "https://www.dxainvest.com/legal/termos-de-uso",
    termsOfUseUrlEn: "https://en.dxainvest.com/legal/termos-de-uso",
    UserTypeEnum,
    userProfileData: null,
    isBriefing: null,
    showBriefingSecurityAlert: false,
  }),

  async created() {
    if (this.$route.query.modal == "true" || this.$route.query.value) {
      this.openModal = true;
      this.value = this.$route.query.value;
      this.$router.replace({ query: { modal: undefined } });
    }
    if (
      this.$route.params.type == undefined ||
      this.$route.params.type == null
    ) {
      this.isBriefing = null;
    } else if (this.$route.params.type == "briefing") {
      this.isBriefing = true;
    } else {
      this.isBriefing = false;
    }
    this.loading = true;
    this.user = JSON.parse(localStorage.getItem("user"));
    this.briefingSecurityAlert();

    var user_type = this.user.type;
    if (
      user_type == 1 ||
      user_type == 4 ||
      user_type == 5 ||
      user_type == 7 ||
      this.user.signed_nda
    ) {
      this.signed_nda = true;
    }
    this.company_id = this.$route.params.company_id;

    this.briefing_id = this.$route.params.briefing_id;

    if (this.company_id) {
      var exists = await this.check_if_company_exists();
      if (exists) {
        await this.get_company();
        await this.reload_presentation();
        await this.get_files();
        await this.get_highlights();

        // DISPARANDO EVENTO DO BANKER
        if (this.user.type === UserTypeEnum.Banker) {
          await this.getUserProfile();

          mixpanel.track("BankerDealPage_View", {
            partner_id: this.userProfileData.partner.id,
            user_type: "banker",
            user_id: this.userProfileData.id,
            deal_name: this.company.name,
            num_of_open_allocation_requests:
              this.company.totalOpenAllocationRequests,
            num_of_suggestions: this.company.totalSugestedAllocations,
          });
        }
      } else {
        this.$router.push("/*");
      }
    }
  },
  mounted() {},
  watch: {
    "$i18n.locale"() {
      this.reload_presentation();
      this.filter_files();
      this.get_highlights();
    },
  },
  computed: {
    ...mapState(["lang"]),
  },
  methods: {
    briefingSecurityAlert() {
      switch (this.user.type) {
        case this.UserTypeEnum.Banker:
          this.briefingSecurityAlert = true;
          break;
        case this.UserTypeEnum.Partner:
          // validando se não é um partner+manager
          if (!this.user.managerPartner || !this.user.managerPartner.id) {
            this.briefingSecurityAlert = true;
          } else {
            this.briefingSecurityAlert = false;
          }
          break;
        default:
          this.briefingSecurityAlert = false;
      }
    },
    async getUserProfile() {
      this.loading = true;
      await this.apiService
        .getRequest("user/user-profile")
        .then((resp) => {
          this.userProfileData = resp.content;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.error(this.$t("an_error_ocurred"));
        });
    },
    async get_highlights() {
      if (this.company && this.company.briefing.id > 0) {
        await this.apiService
          .getRequest(`companyhighlight/list/${this.company.briefing.id}`)
          .then((resp) => {
            this.highlights = JSON.parse(resp.message);
          })
          .catch((error) => {});
      }
    },
    async get_files() {
      if (this.briefing_id != 0 && !(this.isBriefing === false)) {
        await this.apiService
          .getRequest(`companyfile/list/briefing/${this.briefing_id}`)
          .then((resp) => {
            this.files = resp.content;
            this.filter_files();
          });
      }
    },
    filter_files() {
      this.files_filtered = this.files.filter(
        (x) => this.gs.getLanguage(x.language) === this.$i18n.locale
      );
    },
    async get_company() {
      await this.apiService
        .postRequest("companybriefing/get", {
          CompanyId: parseInt(this.company_id),
          IsBriefing: this.isBriefing,
          BriefingId: parseInt(this.briefing_id ? this.briefing_id : 0),
        })
        .then((resp) => {
          this.company = resp.content;
          if (this.company.briefing.introVideo) {
            this.company.briefing.introVideo = JSON.parse(
              this.company.briefing.introVideo
            );
          }
          if (
            this.company.minAllocationValue != null &&
            this.gs.isJson(this.company.minAllocationValue)
          ) {
            this.company.minAllocationValue = JSON.parse(
              this.company.minAllocationValue
            );
          }
          if (this.company.briefing != null) {
            //coloca o briefing real da rodada.
            this.briefing_id = this.company.briefing.id;
          }
        })
        .catch((error) => {
          if (error.status == 404) {
            this.$router.push("/*");
          } else if (error.status != 403) {
            this.$toast.error(this.$t(error.body.message));
          }
        });
      this.loading = false;
    },
    async check_if_company_exists() {
      var exists = false;
      await this.apiService
        .getRequest("company/exist/".concat(this.company_id))
        .then((resp) => {
          exists = resp.content.exists;
        })
        .catch((err) => {
          this.$toast.error(this.$t(err.body.message));
        });
      return exists;
    },
    async reload_presentation() {
      this.slidesLoading = true;
      if (this.briefing_id != 0) {
        await this.apiService
          .getRequest(
            `companybriefing/presentation/${this.briefing_id}/${this.$i18n.locale}`
          )
          .then((resp) => {
            var slides = JSON.parse(resp.message);
            this.slides = slides.map((x) => this.gs.get_photo_path(x));
          });
      }
      this.slidesLoading = false;
    },
    openTermsOfUseOrPrivacyPolicy(to) {
      const lang = localStorage.getItem("lang");
      const isTermsOfUse = to === "termsOfUse";

      if (lang === "pt") {
        let win = window.open(
          isTermsOfUse ? this.termsOfUseUrlPt : this.privacyPolicyUrlPt,
          "_blank"
        );
        win.focus();
      } else {
        let win = window.open(
          isTermsOfUse ? this.termsOfUseUrlEn : this.privacyPolicyUrlEn,
          "_blank"
        );
        win.focus();
      }
    },
    presentation_fullscreen() {
      this.dialog = true;
    },
    go_to_options() {
      var i;
      var all_options = [
        { title: "analysis", text: this.$t("company_analysis") },
        { title: "presentation", text: this.$t("company_presentation") },
        { title: "files", text: this.$t("files"), icon: "mdi-arrow-down" },
        { title: "financial", text: this.$t("financial") },
        { title: "highlights", text: this.$t("highlights") },
        {
          title: "investment-conditions",
          text: this.$t("investment_conditions"),
        },
        { title: "leadership-chat", text: this.$t("leadership_chat") },
        { title: "market-news", text: this.$t("market_news") },
        { title: "media", text: this.$t("media") },
        { title: "team", text: this.$t("team") },
      ];
      var return_list = [];
      for (i = 0; i < all_options.length; i++) {
        var elementExists = document.getElementById(all_options[i].title);
        if (elementExists) {
          return_list.push(all_options[i]);
        }
      }
      return return_list;
    },
    scroll(section) {
      const element = document.getElementById(section);
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
