<template>
  <v-container
    class="dxa-briefing-card mt-4"
    :style="gs.isMobile() ? 'padding: 12px 4px 12px 4px !important' : ''"
    id="company-presentation"
    fluid
    tag="section"
  >
    <div class="ma-5" style="margin-top: 5px !important">
      <v-row align="center">
        <h4
          class="dxa-briefing-section-header h4"
          :style="gs.isMobile() ? 'padding-left: 12px !important' : ''"
        >
          {{ $t("company_presentation") }}
        </h4>
        <v-spacer></v-spacer>
        <v-btn right icon @click="() => handleFullScreen()"
          ><v-icon large color="primary">mdi-fullscreen</v-icon></v-btn
        >
      </v-row>
      <!-- quando for um slide vertical, mostramos só a capa da apresentação. -->
      <div v-if="IsVerticalPresentation" class="vertical-front-cover-container">
        <!-- pega sempre a primeira imagem. Ou seja, a capa. -->
        <div>
          <img
            @click="() => handleFullScreen()"
            :src="Slides[0]"
            class="vertical-front-cover-image"
            alt="presentation-img"
          />
        </div>
      </div>
      <!-- dialog para apresentação vertical. Com todo o conteúdo. -->
      <v-dialog v-model="openPresentationVerticalDialog">
        <v-card class="vertical-dialog-card">
          <div class="vertical-dialog-container">
            <div v-for="(slide, i) in Slides" :key="i">
              <img
                :src="slide"
                class="vertical-dialog-image"
                alt="presentation-img"
              />
            </div>
          </div>
        </v-card>
      </v-dialog>

      <!-- slide para apresentação horizontal (default) -->
      <v-content
        v-if="!IsVerticalPresentation"
        style="padding: 0; background-color: var(--white)"
        class="mx-auto my-4"
      >
        <div class="thumb-example">
          <swiper
            class="swiper gallery-top"
            :options="swiperOptionTop"
            ref="swiperTop"
          >
            <swiper-slide v-for="(slide, i) in Slides" :key="i">
              <v-img :src="slide"></v-img>
            </swiper-slide>
            <div
              class="swiper-button-next swiper-button-white"
              slot="button-next"
            ></div>
            <div
              class="swiper-button-prev swiper-button-white"
              slot="button-prev"
            ></div>
          </swiper>
          <swiper
            class="swiper gallery-thumbs"
            :options="swiperOptionThumbs"
            ref="swiperThumbs"
          >
            <swiper-slide v-for="(slide, i) in Slides" :key="i">
              <v-img :src="slide"></v-img>
            </swiper-slide>
          </swiper>
        </div>
      </v-content>
    </div>
  </v-container>
</template>

<style lang="scss" scoped>
.thumb-example {
  @media only screen and (max-width: 600px) {
    max-width: 100% !important;
  }
  box-shadow: 1px 1px 3px 1px;
  margin-right: auto;
  margin-left: auto;
  height: auto;
  min-height: 35vw;
  max-width: 60% !important;
  background-color: var(--dark);
}
.swiper {
  .swiper-slide {
    background-size: cover;
    background-position: 50%;
  }
  &.gallery-top {
    height: 80%;
    width: 100%;
  }
  &.gallery-thumbs {
    height: 20%;
    box-sizing: border-box;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  &.gallery-thumbs .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }
  &.gallery-thumbs .swiper-slide-active {
    opacity: 1;
  }
}

.presentation {
  max-width: 80% !important;
  height: auto;
  margin: 0 auto;
  min-height: 35vw;
}

.v-image.v-responsive.theme--dark {
  height: 100% !important;
}
/* .presentation .v-responsive,
.presentation .v-carousel {
  height: auto !important;
} */

.v-image__image.v-image__image--cover {
  box-shadow: inset 0px 0px 2px 0px var(--dark);
}

.presentation .v-image__image {
  background-size: contain;
}

.v-window-item .v-window.v-item-group.theme--dark.v-carousel {
  height: auto !important;
}

.vertical-front-cover-container {
  max-width: 800px;
  overflow: hidden;
  text-align: center;
  margin: 32px auto 0px auto;
}

.vertical-front-cover-image {
  max-width: 100%;
  max-height: 520px;
  width: auto;
  height: auto;
  cursor: pointer;
}

.vertical-dialog-card {
  background-color: rgba(0, 0, 0, 0.5) !important;
  backdrop-filter: blur(4px) !important;
}

.vertical-dialog-container {
  padding-top: 32px;
  padding-bottom: 32px;
  max-height: 1020px;
  overflow: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.vertical-dialog-image {
  max-width: 100%;
  max-height: 1020px;
  width: auto;
  height: auto;
}

@media only screen and (max-width: 769px) {
  .vertical-dialog-container {
    padding-left: 16px;
    padding-right: 16px;
    max-height: 620px;
  }

  .vertical-dialog-image {
    max-height: 620px;
  }
}
</style>

<style lang="scss" scoped>
@import "@/sass/briefing.scss";
@import "@/sass/variables.scss";

.embed-container {
  --video--width: 1075;
  --video--height: 515;
  background: none;
  width: 80%;
}

@media only screen and (max-width: 600px) {
  .embed-container {
    --video--width: 600;
    --video--height: 390;
    width: 75%;
  }
  .presentation .v-carousel {
    top: 20% !important;
  }

  .presentation {
    max-width: 100%;
  }

  .v-image.v-responsive.v-carousel__item.theme--dark {
    height: 100% !important;
  }
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  name: "BriefingCompanyPresentation",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    Slides: Array,
    IsVerticalPresentation: Boolean,
  },
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    model: 0,
    openPresentationVerticalDialog: false,
    swiperOptionTop: {
      loop: true,
      loopedSlides: 5, // looped slides should be the same
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
    swiperOptionThumbs: {
      loop: true,
      loopedSlides: 5, // looped slides should be the same
      spaceBetween: 10,
      centeredSlides: true,
      slidesPerView: "auto",
      touchRatio: 0.2,
      slideToClickedSlide: true,
    },
  }),

  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.$swiper;
      const swiperThumbs = this.$refs.swiperThumbs.$swiper;
      swiperTop.controller.control = swiperThumbs;
      swiperThumbs.controller.control = swiperTop;
    });
  },
  async created() {},
  computed: {},
  methods: {
    get_image_path(path) {
      return path;
    },
    handleFullScreen() {
      if (this.IsVerticalPresentation) {
        this.openPresentationVerticalDialog = true;
      } else {
        this.$emit("fullscreen");
      }
    },
  },
};
</script>
